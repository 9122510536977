<template>
    <div>
        <el-card>
            <nav class="out">
                <label for="" style="font-size: 14px; float: left; transform: translateY(9px)">时间：</label>
                <nav class="input">
                    <el-date-picker
                        v-model="date"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        style="width: 100%"
                        size="medium"
                    />
                </nav>

                <nav class="input">
                    <el-input v-model="title" size="medium" placeholder="输入标题" />
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="year" placeholder="年份" size="medium">
                        <el-option label="全部" :value="-1" />

                        <el-option v-for="(item, index) in area.reportYear" :key="index" :label="item" :value="index" />
                    </el-select>
                </nav>

                <!-- <nav class="input" style="width: 150px">
                    <el-select v-model="publishStatus" placeholder="发布状态" size="medium">
                        <el-option label="全部" :value="-1" />
                        <el-option label="未发布" :value="0" />
                        <el-option label="发布" :value="1" />
                    </el-select>
                </nav> -->
                <!-- <nav class="input" style="width: 150px">
                    <el-select v-model="reportBusiness" placeholder="行业" size="medium">
                        <el-option label="全部" :value="-1" />
                        <el-option v-for="(i, q) in area.reportBusiness" :key="q" :label="i" :value="q" />
                    </el-select>
                </nav> -->
                <!-- <nav class="input" style="width: 150px">
                    <el-select v-model="reportArea" placeholder="地区" size="medium">
                        <el-option label="全部" :value="-1" />
                        <el-option v-for="(i, q) in area.reportArea" :key="q" :label="i" :value="q" />
                    </el-select>
                </nav> -->
                <nav class="input" style="width: 150px;">
                    <el-input v-model="authName" size="medium" placeholder="编辑人员" />
                </nav>

                <nav class="input">
                    <el-button size="small" @click="reset">重置</el-button>
                    <el-button type="primary" size="small" @click="subimt">{{ btn ? "搜索" : "loading.." }}</el-button>
                </nav>
            </nav>
        </el-card>

        <el-card style="margin-top: 10px">
            <el-table
                ref="tableRef"
                :data="list"
                style="width: 100%"
                border
                stripe
                :header-cell-style="rowClass"
                @sort-change="sortChange"
                @filter-change="filterReportBusiness"
            >
                <!-- <el-table-column type="expand">
                    <template slot-scope="{ row }">
                        <el-descriptions title="" border style="margin: 5px 10px;">
                            <el-descriptions-item label="用户名">kooriookami</el-descriptions-item>
                            <el-descriptions-item label="手机号">18100000000</el-descriptions-item>
                            <el-descriptions-item label="居住地">
                                <img :src="baseurl + yu + row.banner" alt="" style="width: 150px" />
                            </el-descriptions-item>
                            <el-descriptions-item label="备注">
                                <el-tag size="small">学校</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item label="联系地址"
                                >江苏省苏州市吴中区吴中大道 1188 号</el-descriptions-item
                            >
                        </el-descriptions>
                    </template>
                </el-table-column> -->
                <el-table-column prop="id" width="100" label="编号" sortable="custom" />
                <el-table-column prop="banner" label="配图" width="180">
                    <template slot-scope="ew">
                        <img :src="baseurl + yu + ew.row.banner" alt="" style="width: 150px" />
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="标题" width="180">
                    <template slot-scope="ew">
                        <p>
                            <img v-show="ew.row.top == 1" src="../../assets/top.png" alt="" class="img-p" />
                            <a
                                :href="`https:/${yu}/report/${ew.row.id}.html`"
                                target="“_blank”"
                                style="  text-decoration: underline;"
                                >{{ ew.row.title }}</a
                            >
                        </p>

                        <p v-if="ew.row.pdfUrl">
                            <a :href="baseurl + yu + ew.row.pdfUrl" target="“_blank”">(pdf文件链接)</a>
                        </p>
                        <p v-if="ew.row.preview">
                            <a :href="ew.row.preview" target="“_blank”">
                                (PC预览)
                            </a>
                        </p>
                        <p v-if="ew.row.previewSunCode">
                            <a :href="baseurl + yu + ew.row.previewSunCode" target="“_blank”">(小程序预览)</a>
                        </p>
                    </template>
                </el-table-column>
                <el-table-column prop="authName" label="编辑人员" />
                <!-- <el-table-column prop="content" label="内容"> </el-table-column> -->
                <el-table-column
                    prop="areaCode"
                    label="地区"
                    :filter-multiple="false"
                    column-key="reportArea"
                    :filters="getFilters(area.reportArea)"
                >
                    <template slot-scope="scope">
                        {{ scope.row.areaCode | getarea }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="businessId"
                    label="行业"
                    :filter-multiple="false"
                    column-key="reportBusiness"
                    :filters="getFilters(area.reportBusiness)"
                >
                    <template slot-scope="scope">
                        {{ scope.row.businessId | getbu }}
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="createTime" label="时间"> </el-table-column> -->
                <el-table-column prop="source" label="来源" />

                <!-- <el-table-column prop="desc" label="描述"> </el-table-column> -->

                <!-- <el-table-column prop="desc" label="描述"> </el-table-column> -->
                <el-table-column prop="views" label="查看次数" sortable="custom" />
                <el-table-column prop="orderNum" label="排序" sortable="custom">
                    <template #header>
                        <span>排序</span>
                        <br /><span style="font-size:12px">（当前最大值：{{ orderMax || 0 }}）</span>
                        <el-tooltip effect="dark" placement="top">
                            <div slot="content">数值越大，显示越靠前</div>
                            <i class="el-icon-warning-outline" style="margin-left: 5px;" />
                        </el-tooltip>
                    </template>
                    <template slot-scope="scope">
                        <div class="input-box">
                            <el-input
                                v-if="pai[scope.$index].pai"
                                v-model="scope.row.orderNum"
                                size="small"
                                class="id"
                                @blur="handleInputBlur(scope.$index, scope.row.id, scope.row.orderNum)"
                            />
                        </div>
                        <span
                            v-if="!pai[scope.$index].pai"
                            v-show="$store.state.btn_if.indexOf('report_list_order') != -1"
                            :class="$store.state.btn_if.indexOf('report_list_order') != -1 && 'el-icon-edit'"
                            @click="showinput(scope.$index)"
                        >
                            {{ scope.row.orderNum }}</span
                        >
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="allViews" label="真实浏览量" /> -->
                <el-table-column prop="clickViews" label="浏览量" />
                <el-table-column prop="viewType" label="查看方式" width="170px">
                    <template slot-scope="ew">
                        <!-- {{ ew.row.viewType | getviewType }} -->
                        <el-tag v-if="ew.row.viewType == 1" type="warning">在线查看</el-tag>
                        <el-tag v-else type="warning">下载</el-tag>
                        <div style="margin: 5px;">
                            <el-tag type="warning">{{ reportPaymentType[ew.row.type] }}</el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop=""
                    label="状态"
                    :filter-multiple="false"
                    column-key="publishStatus"
                    :filters="getFilters({ 0: '未发布', 1: '发布' })"
                >
                    <template slot-scope="ew">
                        <el-tag v-if="ew.row.publishStatus == 1" type="warning">已发布</el-tag>
                        <el-tag v-else type="warning">未发布</el-tag>
                        <el-tag v-if="ew.row.hot == 1" type="warning" style="margin: 5px;">{{
                            ew.row.hot == 1 ? "热门" : ""
                        }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="发布时间" />

                <el-table-column label="操作" width="150">
                    <template slot-scope="ew">
                        <el-button
                            v-show="$store.state.btn_if.indexOf('report_list_edit') != -1"
                            type="text"
                            icon="el-icon-edit"
                            size="mini"
                            class="skyblue"
                            @click="edit(ew.row.id)"
                            >编辑</el-button
                        >
                        <el-button
                            v-show="$store.state.btn_if.indexOf('report_list_delete') != -1"
                            type="text"
                            icon="el-icon-delete"
                            size="mini"
                            class="red"
                            @click="open(ew.row.id)"
                            >删除</el-button
                        >

                        <el-button
                            v-if="ew.row.publishStatus == 0"
                            v-show="$store.state.btn_if.indexOf('report_list_publish') != -1"
                            type="text"
                            icon="el-icon-s-promotion"
                            size="mini"
                            class="yellow"
                            @click="show(ew.row.id, 1)"
                            >发布</el-button
                        >

                        <el-button
                            v-show="$store.state.btn_if.indexOf('report_list_publish') != -1"
                            v-else
                            type="text"
                            icon="el-icon-s-promotion"
                            size="mini"
                            class="yellow"
                            @click="show(ew.row.id, 0)"
                            >取消发布</el-button
                        >
                        <el-button
                            v-if="true"
                            v-show="$store.state.btn_if.indexOf('report_hot') != -1"
                            type="text"
                            icon="iconfont icon-remen"
                            size="mini"
                            style="color:red;"
                            @click="hot(ew.row)"
                            >&nbsp;{{ ew.row.hot == 1 ? "取消热门" : "热门" }}</el-button
                        >
                        <el-button
                            v-show="$store.state.btn_if.indexOf('report_top') != -1"
                            type="text"
                            icon="el-icon-top"
                            size="mini"
                            style="color: tan;"
                            @click="setTop(ew.row, ew.row.top == 0 ? 1 : 0)"
                            >{{ ew.row.top == 0 ? "置顶" : "取消置顶" }}</el-button
                        >
                        <!-- <el-button
              v-show="$store.state.btn_if.indexOf('report_list_type') != -1"
              type="text"
              icon="el-icon-camera"
              size="mini"
              style="color: green;"
              @click="toView(ew.row, ew.row.type == 0 ? 1 : 0)"
              >{{ ew.row.type == 0 ? '免费查看' : '付费查看' }}</el-button
            > -->
                        <el-button
                            v-show="$store.state.btn_if.indexOf('report_log_export') != -1"
                            type="text"
                            icon="el-icon-bottom"
                            size="mini"
                            :loading="ew.row.loading"
                            @click="checkRecord(ew.row)"
                            >导出查看记录</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :current-page="currentPage"
                background
                :page-sizes="[3, 5, 10, 15]"
                :page-size="pagesize"
                layout="total, prev, pager, next"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </el-card>
        <el-dialog title="新增报告" :visible.sync="dialogVisible" width="50%">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="标题">
                    <el-input v-model="form.title" />
                </el-form-item>
                <el-form-item label="描述">
                    <el-input v-model="form.desc" />
                </el-form-item>
                <el-form-item label="来源">
                    <el-input v-model="form.source" />
                </el-form-item>
                <el-form-item label="地区">
                    <el-select v-model="form.areaCode" placeholder="请选择">
                        <el-option
                            v-for="item in area.reportArea"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                            :data="action"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="查看方式">
                    <el-select v-model="form.viewType" placeholder="请选择">
                        <el-option label="在线查看" :value="1" />
                        <el-option label="下载" :value="2" />
                    </el-select>
                </el-form-item>
                <el-form-item label="行业">
                    <el-select v-model="form.businessId" placeholder="请选择">
                        <el-option
                            v-for="item in area.reportBusiness"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="配图">
                    <el-upload
                        class="avatar-uploader"
                        :action="this.$store.getters.imgUrl"
                        :headers="header"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :data="action"
                    >
                        <img v-if="form.banner" :src="form.banner" class="avatar" style="width: 150px" />
                        <i v-else class="el-icon-plus avatar-uploader-icon" />
                    </el-upload>
                </el-form-item>
                <el-form-item label="内容">
                    <quill-editor ref="myQuillEditor" v-model="form.content" :options="quillOption" />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button
                    @click="
                        dialogVisible = false;
                        form = {};
                    "
                    >取 消</el-button
                >
                <el-button type="primary" @click="add">{{ btn ? "确 定" : "loading.." }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
let that;
import $ from "jquery";
import { reportPaymentType } from "@/utils/dictionary.js";
export default {
    filters: {
        getarea: function(value) {
            // console.log(that.area.reportArea,value);
            if (that.area.reportArea) {
                var reportArea = that.area.reportArea[value];
                return reportArea;
            }
        },
        getbu(value) {
            if (that.area.reportBusiness) {
                var reportBusiness = that.area.reportBusiness[value];
            }
            return reportBusiness;
        },
        getviewType(val) {
            if (val == 1) {
                return "在线查看";
            } else {
                return "下载";
            }
        },
        getstau(val) {
            if (val == 1) {
                return "已发布";
            } else {
                return "未发布";
            }
        },
    },
    data() {
        return {
            date: [],
            title: "",
            year: "",
            publishStatus: "",
            reportArea: "",
            reportBusiness: "",
            authName: "",
            order: "",
            btn: true,
            action: {
                action: "report",
            },
            dioaddkuai: false,
            currentPage: 1,
            pagesize: 10,
            list: [],
            pai: [],
            paiform: {},
            value: "",
            dialogVisible: false,
            dialogVisiblexui: false,
            baseurl: this.$store.state.env.cos,
            yu: "/" + this.$store.state.env.domain,
            total: 0,
            form: {
                title: "",
                source: "",
                picture: "",
                content: "",
                desc: "",
                areaCode: "",
                banner: "",
                viewType: "",
                businessId: "",
            },
            form_1: {
                id: "",
                title: "",
                source: "",
                picture: "",
                content: "",
                desc: "",
                areaCode: "",
                banner: "",
                viewType: "",
                businessId: "",
            },
            form_2: {
                page: 1,
                pageSize: 10,
                date: "",
                title: "",
                year: "",
                publishStatus: "",
                reportArea: "",
                reportBusiness: "",
                order: "",
                authName: "",
            },
            imageUrl: "",
            imageUrl_1: "",
            header: {
                Token: sessionStorage.getItem("token"),
            },
            area: [],
            orderMax: 0,
            reportPaymentType,
        };
    },
    beforeCreate() {
        that = this;
    },
    updated() {
        //   console.log(this.form);
    },
    created() {
        this.getlist();
        sessionStorage.setItem("action", "report");
    },

    methods: {
        async hot(row) {
            // console.log(row);
            const { data: res } = await this.$http.post("/admin/Report/hot", {
                id: row.id,
                hot: row.hot == 1 ? 0 : 1,
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
            }
            this.getlist();
        },
        async checkRecord(row) {
            row.loading = true;
            this.list = [...this.list];
            let { data: res } = await this.$http.get("/admin/Report/reportExport", {
                params: {
                    id: row.id,
                },
            });
            if (res.errorCode == 200) {
                this.$toUrl(res.data.url);
            }

            row.loading = false;
            this.list = [...this.list];
        },
        async toView(row, val) {
            var { data: res } = await this.$http.post("/admin/Report/editType", {
                id: row.id,
                type: val,
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
            } else {
                this.$message({
                    message: res.message,
                    type: "error",
                    customClass: "zZindex",
                });
            }
        },
        setTop(row, val) {
            this.$http
                .post("/admin/Report/top", {
                    id: row.id,
                    top: val,
                })
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.$message.success(res.message);
                        this.getlist();
                    } else {
                        this.$message({
                            message: res.message,
                            type: "error",
                            customClass: "zZindex",
                        });
                    }
                    // this.btn1 = true
                });
        },
        reset() {
            // this.currentPage = 1;
            // this.date = [];
            // this.title = "";
            // this.year = "";
            // this.publishStatus = "";
            // this.reportArea = "";
            // this.reportBusiness = "";
            // this.authName = "";
            this.$nextTick(() => {
                this.$refs.tableRef.clearFilter();
                this.$refs.tableRef.clearSort();
            });
            Object.assign(this.$data, this.$options.data.call(this));
            this.getlist();
        },
        getFilters(object) {
            let arr = [];
            for (const key in object) {
                arr.push({ text: object[key], value: key });
            }
            return arr;
        },
        filterReportBusiness(filters) {
            for (const key in filters) {
                this[key] = filters[key].toString();
            }
            this.subimt();
        },
        subimt() {
            this.currentPage = 1;
            const form = {
                page: this.currentPage,
                pageSize: this.pagesize,
                date: this.date?.join("~"),
                title: this.title,
                year: this.year,
                publishStatus: this.publishStatus,
                reportArea: this.reportArea,
                reportBusiness: this.reportBusiness,
                order: this.order,
                authName: this.authName,
            };
            this.form_2 = form;
            this.getlist();
        },
        sortChange(c) {
            // console.log(c);
            if (c.order) {
                var s = "activity_" + c.prop;
                if (c.prop == "orderNum") {
                    s = "report_order_num";
                } else if (c.prop == "views") {
                    s = "report_views";
                } else {
                    s = "report_" + c.prop;
                }
                var p = c.order.slice(0, c.order.length - 6);
                this.order = s + " " + p;
            } else {
                this.order = "";
            }

            this.getlist();
        },
        showinput(id) {
            console.log(id);
            this.pai[id].pai = true;
            setTimeout(() => {
                $(".id input").focus();
            }, 20);
        },
        handleInputBlur(id, ids, orderNum) {
            this.paiform.id = ids;
            this.paiform.orderNum = orderNum;
            this.$http.post("/admin/Report/sort", this.paiform).then(({ data: res }) => {
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                }
            });
            this.pai[id].pai = false;
        },
        addkuai() {
            this.addkuai = false;
        },
        getea() {
            this.$http.get("/admin/ReportCategory/getList").then(({ data: res }) => {
                if (res.errorCode == 200) {
                    this.area = res.data;
                }
            });
        },
        rowClass() {
            return "background:#F5F7FA;";
        },
        handleSizeChange(e) {
            this.pagesize = e;
            console.log(e);
        },
        handleCurrentChange(e) {
            this.currentPage = e;
            // this.getlist()
            var form = this.form_2;
            form = {
                ...form,
                page: this.currentPage,
            };
            this.$http
                .get("/admin/Report/getList", {
                    params: form,
                })
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        console.log(res);
                        this.list = res.data.list;
                        this.total = res.data.totalCount;
                        // this.getarea()
                        this.list.forEach((item) => {
                            this.pai.push({ id: item.id, pai: false });
                        });
                        this.getea();
                    }
                });
        },
        getlist() {
            this.$http
                .get("/admin/Report/getList", {
                    params: {
                        page: this.currentPage,
                        pageSize: this.pagesize,
                        date: this.date.join("~"),
                        title: this.title,
                        year: this.year,
                        publishStatus: this.publishStatus,
                        reportArea: this.reportArea,
                        reportBusiness: this.reportBusiness,
                        order: this.order,
                        authName: this.authName,
                    },
                })
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        console.log(res);
                        this.list = res.data.list;
                        this.total = res.data.totalCount;
                        this.orderMax = res.data.orderMax;
                        // this.getarea()
                        this.list.forEach((item) => {
                            this.pai.push({ id: item.id, pai: false });
                        });
                        this.getea();
                    }
                });
        },

        handleAvatarSuccess(res, file) {
            if (res.errorCode == 200) {
                this.form.banner = res.data.url;
            }
            console.log(file);
            //   console.log(imgurlbase)
        },
        // beforeAvatarUpload(file) {
        //   const isJPG = file.type === 'image/jpeg'
        //   const isLt2M = file.size / 1024 / 1024 < 2

        //   if (!isJPG) {
        //     this.$message.error('上传头像图片只能是 JPG 格式!')
        //   }
        //   if (!isLt2M) {
        //     this.$message.error('上传头像图片大小不能超过 2MB!')
        //   }
        //   return isJPG && isLt2M
        // },
        handleAvatarSuccess_1(res, file) {
            if (res.errorCode == 200) {
                this.form_1.banner = res.data.url;
            }
            console.log(file);
            //   console.log(imgurlbase)
        },
        beforeAvatarUpload_1(file) {
            const isJPG = file.type === "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error("上传头像图片只能是 JPG 格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            return isJPG && isLt2M;
        },
        add() {
            if (this.btn) {
                // this.form.picture = this.form.picture.slice(this.baseurl.length)
                this.$http.post("/admin/Report/add", this.form).then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.$message.success("操作成功");
                        this.dialogVisible = false;
                        location.reload();
                        console.log(this.form);
                    }
                    this.btn = true;
                    // if(res.)
                });
            }
            this.btn = false;
        },
        addedit() {
            if (this.btn) {
                // this.form.picture = this.form.picture.slice(this.baseurl.length)

                this.$http.post("/admin/News/edit", this.form_1).then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.dialogVisiblexui = false;
                        this.$message.success(res.message);
                        this.getlist();
                    }
                    this.btn = true;
                });
            }
            this.btn = false;
        },
        edit(id) {
            this.$router.push({
                path: "/report_add",
                query: { id: id },
            });
            sessionStorage.setItem("flag", false);
            this.$http.get("/admin/Report/getById?id=" + id).then(({ data: res }) => {
                if (res.errorCode == 200) {
                    this.dialogVisiblexui = true;
                    // console.log(res);
                    this.form_1 = res.data;
                }
            });
        },
        open(id) {
            this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$http.delete("/admin/Report/del", { params: { id: id } }).then((res) => {
                        //   console.log(res)
                        if (res.data.errorCode == 200) {
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                            if (this.total % 10 == 1) {
                                this.currentPage--;
                            }
                            this.getlist();
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        show(id, el) {
            this.$http.post("/admin/Report/publish", { id: id, publishStatus: el }).then(({ data: res }) => {
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    this.getlist();
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.out {
    // width: 500px;

    margin-bottom: 20px;
    .input {
        width: 37%;
        display: inline-block;
        margin-right: 30px;
    }
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
.skyblue {
    color: skyblue;
}
.green {
    color: green;
}
.yellow {
    color: pink;
}
.out .input {
    width: 18%;
}
</style>
